
import { Vue, Component, Watch, Prop } from 'vue-property-decorator';
import { State, Action, Getter, Mutation } from 'vuex-class';
import DefaultLayout from '@/components/DefaultLayout.vue';
import { CustomerService } from '@/services/customer-service';
import { AddressService } from '@/services/address-service';
import { SetSnackBar } from '@/helpers/SnackbarHelper';
import ItemService from '@/services/item-service';
import { Brand } from '@/store/models/ItemModel';
import PreviewFnskuComboDialog from '@/components/PreviewFnskuComboDialog.vue';
import EditBrandDialog from '@/components/BrandEditDialog.vue';
import BrandAddDialog from '@/components/BrandAddDialog.vue';
import { DateHelper } from '@/helpers/DateHelper';
import {
  ManufacturingCountryTextTypes,
  TransparencyItemModel
} from '@/store/models/TransparencyItemModel';
@Component({
  components: {
    DefaultLayout,
    PreviewFnskuComboDialog,
    EditBrandDialog,
    BrandAddDialog
  }
})
export default class extends Vue {
  $refs!: {
    defaultActions: HTMLFormElement;
    form: HTMLFormElement;
    PreviewFnskuComboDialog: HTMLFormElement;
    EditBrandDialog: HTMLFormElement;
    BrandAddDialog: HTMLFormElement;
  };
  /* Properties */
  /* Store Actions */
  @Action('refreshItem', { namespace: 'items' })
  refreshItem!: any;
  @Action('setPartNumbers', { namespace: 'items' })
  setPartNumbers!: any;
  @Action('GetCustomerItemsLimited', { namespace: 'items' })
  GetCustomerItemsLimited!: any;
  @Action('GetItems', { namespace: 'items' })
  GetItems!: (model: any) => Promise<any>;
  /* Watchers */
  @Watch('brandSelect')
  onBrandSelectChange(val: number) {
    if (val === -1) {
      this.dialog = true;
    }
    this.shouldShowFnskuPreviewBtn();
  }
  @Watch('selectedTab', { deep: true })
  onSelectedTabChange(val: number) {
    this.updateHumanReadableFromSelectedLabelType();
    this.shouldShowFnskuPreviewBtn();
  }
  @Watch('gtinType')
  onGtinTypeChange(val: number) {
    var tabs = this.tabs.filter((t: { Gtin: number }) => t.Gtin == val || t.Gtin == 5);
    this.selectedTabs = tabs;
    this.gtinTypeFullObject = this.gtinTypes.find(g => g.id == val);

    if (!tabs.find((t: any) => t.publicId == this.selectedTab.publicId)) {
      this.next(0);
    }
  }
  @Watch('SKU')
  async onSkuChange(val: string) {
    const requiredSku = 'SKU is required';
    const charLimit = 'Character limit of 25';
    if (!val) {
      this.skuErrorMessages.push(requiredSku);
    } else {
      this.filterSkuErrorMessages(requiredSku);
    }
    if (!!val && val.length <= 25) {
      this.filterSkuErrorMessages(charLimit);
    } else {
      this.skuErrorMessages.push(charLimit);
    }
    if (
      !this.skuErrorMessages.includes(requiredSku) &&
      !this.skuErrorMessages.includes(charLimit)
    ) {
      await this.checkForDuplicates();
    }
  }
  /* Data */
  tabs: any = [
    {
      name: '',
      index: 1,
      numberAcross: 0,
      NumberOfRolls: 8,
      MaxLabelsPerRoll: 0,
      level: 1,
      bwlevel: 2,
      height: 0,
      width: 0,
      show: false,
      src: '',
      publicId: ''
    }
  ];
  selectedTab: any = {
    name: 'Preferred',
    numberAcross: 0,
    NumberOfRolls: 8,
    MaxLabelsPerRoll: 0,
    level: 1,
    bwlevel: 2,
    height: 0,
    width: 0,
    show: false,
    src: '',
    publicId: ''
  };
  items: any[] = ['New', 'Used'];
  gtinTypes: any[] = [
    { Name: 'UPC', id: 0, charLength: 12 },
    { Name: 'EAN-8', id: 1, charLength: 8 },
    { Name: 'EAN-13', id: 2, charLength: 13 },
    { Name: 'EAN-14', id: 3, charLength: 14 }
  ];
  gtinType: any = null;
  gtinTypeFullObject: any = { Name: 'GTIN', id: 0, charLength: 12 };
  selectedTabs: any = [];
  dialog: boolean = false;
  valid: boolean = true;
  brandValid: boolean = true;
  active: any = null;
  shouldLoad: boolean = false;
  isLoadingStaticItems: boolean = true;
  shouldLoadAnotherItem: boolean = false;
  isSavingStaticItem: boolean = false;
  isCheckingForDuplicates: boolean = false;
  shouldShowDuplicateAlert: boolean = false;
  addBrandLogoDialog: boolean = false;
  snackbar: boolean = false;
  snackbarTitle: string = '';
  timeout: number = 6000;
  isGeneratingPart: boolean = false;
  NewBrandName: string = '';
  Part: string = '';
  Description: string = '';
  OldSku: string = '';
  GTIN: string = '';
  UPC: string = '';
  SKU: string = '';
  ASIN: string = '';
  Brand: string = '';
  FNSKU: string = '';
  NewUsed: boolean = true;
  Vartext: string = '';
  state: string = '';
  country: { Id: number; CountryValue: string; ShouldUseProvince: boolean } = {
    Id: 1,
    CountryValue: 'USA',
    ShouldUseProvince: false
  };
  province: string = '';
  city: string = '';
  ManufacturingName: string = '';
  previewGraphic1UpFileName: string = '';
  debouceAction: any;
  PartRules: any = [
    (v: any) => !!v || 'Part Number is required',
    (v: any) => (v && v.length <= 20) || 'Character limit of 20',
    (v: any) => !!this.isWin1252(v) || 'Invalid Character'
  ];
  DescriptionRules: any = [
    (v: any) => !!v || 'Description is required',
    (v: any) => !!this.isWin1252(v) || 'Invalid Character'
  ];
  NewBrandRules: any = [
    (v: any) => !!v || 'Brand Name is required',
    (v: any) => !!this.isWin1252(v) || 'Invalid Character'
  ];
  ASINRules: any = [
    (v: any) => !!v || 'ASIN is required',
    (v: any) =>
      (!!v && (v.match(/\W/g) === null || v.length === 0)) ||
      'ASIN must only contain letters or numbers',
    (v: any) =>
      (v && (v.length === 10 || v.length === 0)) || 'ASIN length must be 10 characters',
    (v: any) => !!this.isWin1252(v) || 'Invalid Character'
  ];
  BrandRules: any = [(v: any) => !!v || 'Brand is required'];
  ItemRules: any = [(v: any) => !!v || 'Item is required'];
  CountryRules: any = [(v: any) => !!v || 'Country is required'];
  FNSKURules: any = [
    (v: any) => !v || v.length <= 50 || 'Character limit of 50',
    (v: any) => !!this.isWin1252(v) || 'Invalid Character'
  ];
  Type4FNSKURules: any = [
    (v: any) => !!v || 'FNSKU is required',
    (v: any) => (v && v.length <= 50) || 'Character limit of 50',
    (v: any) => !!this.isWin1252(v) || 'Invalid Character'
  ];
  ManufacturingCityRules: any = [
    (v: any) => !v || v.length <= 64 || 'Character limit of 64',
    (v: any) => !!this.isWin1252(v) || 'Invalid Character'
  ];
  ManufacturingStateRules: any = [
    (v: any) => !v || v.length <= 64 || 'Character limit of 64',
    (v: any) => !!this.isWin1252(v) || 'Invalid Character'
  ];
  ManufacturingProvinceRules: any = [
    (v: any) => !v || v.length <= 64 || 'Character limit of 64',
    (v: any) => !!this.isWin1252(v) || 'Invalid Character'
  ];
  ManufacturingNameRules: any = [
    (v: any) => !v || v.length <= 64 || 'Character limit of 64',
    (v: any) => !!this.isWin1252(v) || 'Invalid Character'
  ];
  brands: Brand[] = [];
  brandSelect: Brand = new Brand({ Id: 0 });
  brandOptions: string[] = ['Edit', 'Preview'];
  varDataSelect: string = 'UPC';
  previousVarDataSelect: string = 'UPC';
  errors: any = [];
  existingItem: any = null;
  typingTimer: any;
  skuErrorMessages: string[] = [];
  varDataTypes: any = [
    {
      Name: 'UPC',
      EnumValue: 'UPC',
      type: 2,
      gtin: 0,
      isVisible: true
    },
    {
      Name: 'SKU',
      EnumValue: 'SKU',
      type: 3,
      gtin: 5,
      isVisible: true
    },
    {
      Name: 'ASIN',
      EnumValue: 'ASIN',
      type: 4,
      gtin: 5,
      isVisible: true
    },
    {
      Name: 'EAN-8',
      EnumValue: 'Ean8',
      type: 8,
      gtin: 1,
      isVisible: true
    },
    {
      Name: 'EAN-13',
      EnumValue: 'Ean13',
      type: 7,
      gtin: 2,
      isVisible: true
    },
    {
      Name: 'EAN-14',
      EnumValue: 'Ean14',
      type: 8,
      gtin: 4,
      isVisible: true
    },
    {
      Name: 'FNSKU',
      EnumValue: 'FNSKU',
      type: 8,
      gtin: 5,
      isVisible: true
    }
  ];
  manufacturingCountryText: ManufacturingCountryTextTypes = {
    id: 0,
    text: 'Manufactured in'
  };
  manufacturingCountryTextTypes: ManufacturingCountryTextTypes[] = [
    { id: 0, text: 'Manufactured in' },
    { id: 1, text: 'Made in' }
  ];
  states: { id: string; name: string }[] = [];
  countries: any[] = [];
  expectedCheckDigit: any = null;
  /* Utility Functions */
  updateHumanReadableFromSelectedLabelType() {
    if (
      !this.selectedTab.CanUseReadableText ||
      !this.existingItem ||
      !this.existingItem.HumanReadable
    ) {
      this.varDataSelect = this.varDataTypes[0].Name;
    } else {
      this.varDataSelect = this.existingItem.HumanReadable;
    }
    if (this.selectedTab.IsType4) {
      this.varDataSelect = this.varDataTypes[6].Name;
    }
  }
  clear() {
    this.$refs.form.resetValidation();
  }
  isFormValid() {
    this.errors = [];
    switch (this.gtinType) {
      case 0:
        if (this.GTIN.length !== 12) this.errors.push('Please enter 12 digits for a UPC');
        break;
      case 1:
        if (this.GTIN.length !== 8)
          this.errors.push('Please enter 8 digits for an EAN8 GTIN');
        break;
      case 2:
        if (this.GTIN.length !== 13)
          this.errors.push('Please enter 13 digits for an EAN13');
        break;
      case 3:
        if (this.GTIN.length !== 14)
          this.errors.push('Please enter 14 digits for an EAN14');
        break;
      case 4:
        if (this.GTIN.length !== 14)
          this.errors.push('Please enter 12 digits for an UCC14');
        break;
    }
    if (this.gtinType === null) {
      this.errors.push('Please select a GTIN Type');
    }
    if (this.GTIN.length !== 0) {
      if (!this.eanCheckDigit(this.GTIN)) {
        this.errors.push(
          `GTIN is not valid expected check digit: ${this.expectedCheckDigit}`
        );
      }
    }
    if (this.active === '') {
      this.errors.push('Please Select a style of label');
    }

    let brand = this.brands.find(b => b.Id === this.brandSelect.Id);
    if (this.selectedTab.IsType4) {
      if (brand && !brand.HasBrandLogo) {
        this.errors.push('Please upload a Logo for your brand.');
      }
      if (!this.FNSKU) {
        this.errors.push('Please enter an FNSKU for your label');
      }
    }
    return this.errors.length === 0;
  }
  checkNewBrand() {
    if (this.brandSelect.Name === 'Add new Brand?') {
      this.$refs.BrandAddDialog.openDialog();
    }
  }
  handleBrandOption(option: string) {
    switch (option) {
      case 'Edit':
        this.$refs.EditBrandDialog.openDialog(this.brandSelect);
        return;
      case 'Preview':
        this.$refs.PreviewFnskuComboDialog.openDialog(this.selectedTab.IsType4);
    }
  }
  reverse(s: string) {
    let splitext = s.split('');
    let revertext = splitext.reverse();
    let reversed = revertext.join('');
    return reversed;
  }
  eanCheckDigit(s: string) {
    var result = 0;
    var removeCheckDigit = s.substring(0, s.length - 1);
    var rs = this.reverse(removeCheckDigit);
    for (let counter = 0; counter < rs.length; counter++) {
      result = result + parseInt(rs.charAt(counter)) * Math.pow(3, (counter + 1) % 2);
    }
    var returnValue = (10 - (result % 10)) % 10,
      checkdigit = Number(s[s.length - 1]);
    var isValidCheckDigit = returnValue == checkdigit;
    if (!isValidCheckDigit) {
      this.expectedCheckDigit = returnValue;
    }
    return isValidCheckDigit;
  }
  next(number: number) {
    this.active = this.tabs[number].name;
    for (var i = 0; i < this.tabs.lengh; i++) {
      this.tabs[i].show = false;
    }
    this.tabs[number].show = true;
    this.selectedTab = this.tabs[number];
  }
  async getStaticItems() {
    const { data } = await ItemService.GetStaticItems();
    this.tabs.length = 0;
    var index = 1;
    let sortedData = data.sort((a: { IsType4: any }) => (a.IsType4 ? -1 : 1));
    for (var item of sortedData) {
      var newTab = {
        name: item.Description,
        numberAcross: 0,
        NumberOfRolls: 8,
        MaxLabelsPerRoll: 0,
        level: 1,
        //itemId: item.Id,
        //bwItemId: item.BwItemId,
        bwlevel: 2,
        height: item.Height,
        width: item.Width,
        show: false,
        src: item.Src,
        index: index,
        publicId: item.PublicId,
        Gtin: item.Gtin,
        CanUseReadableText: item.CanUseReadableText,
        IsType4: item.IsType4
      };
      this.tabs.push(newTab);
      index++;
    }
    this.isLoadingStaticItems = false;
  }
  async saveStaticItem() {
    var model: TransparencyItemModel = {
      ItemId: this.selectedTab.publicId,
      PartNumber: this.SKU,
      Description: this.Description,
      UPC: this.UPC,
      SKU: this.SKU,
      Asin: this.ASIN,
      FNSKU: this.FNSKU,
      EAN13: '',
      BrandId: this.brandSelect.Id,
      HumanReadable: this.varDataTypes.find(
        (v: { Name: string }) => v.Name === this.varDataSelect
      ).EnumValue,
      GTIN: this.GTIN,
      GtinType: this.gtinType,
      IsNew: this.NewUsed,
      ManufacturerName: this.ManufacturingName,
      ManufacturerCountry: this.countries.find(
        (c: { Id: number }) => c.Id === this.country.Id
      ).CountryValue,
      ManufacturerStateProv: this.country.Id === 1 ? this.state : this.province,
      ManufacturerCity: this.city,
      ManufacturedCountryTextType: this.manufacturingCountryText.id,
      StaticItemId: ''
    };
    let hasStaticItemChanged =
      this.existingItem?.StaticItemAssociation.PublicId !== this.selectedTab.publicId;
    if (this.isFormValid()) {
      this.isSavingStaticItem = true;
      try {
        if (this.isEditing) {
          //TODO: Edit api call
          model.ItemId = this.existingItem.Id;
          if (hasStaticItemChanged) {
            model.StaticItemId = this.selectedTab.publicId;
          }
          await ItemService.SaveExistingTransparencyItem(model);
        } else {
          await ItemService.SaveNewTransparencyItem(model);
        }
        await this.GetCustomerItemsLimited();
        if (!this.isEditing || hasStaticItemChanged) {
          await this.GetItems({ ShouldLoad: false });
        }
        SetSnackBar('Item Saved Successfully');
        if (this.shouldLoadAnotherItem) {
          this.Part = '';
          this.Description = '';
          this.GTIN = '';
          this.SKU = '';
          this.ASIN = '';
          this.FNSKU = '';
          this.ManufacturingName = '';
        } else {
          this.$nextTick(() => {
            this.goToManageItems();
          });
        }
      } catch (err) {
        SetSnackBar('There was an error saving your item');
      }
      this.isSavingStaticItem = false;
    }
  }
  async addBrand() {
    try {
      await CustomerService.AddNewBrand(this.NewBrandName);
      this.NewBrandName = '';
      this.getBrandsForCustomer();
      SetSnackBar('Brand Saved Successfully');
    } catch (err) {
      SetSnackBar('There was an error adding your brand');
    }
  }

  async getBrandLogo(brand: Brand) {
    if (brand === this.brandSelect) {
      this.brands.find(
        b => b.Id === this.brandSelect.Id
      )!.Src = `/Item/GetBrandLogo?brandId=${brand}`;
    } else {
      brand.Src = `/Item/GetBrandLogo?brandId=${brand.Id}`;
    }
    const { data } = await ItemService.GetBrandLogo(brand.Id);
    brand.Src = data;
  }
  async getBrandsForCustomer(defaultBrand: Brand | null = null) {
    this.brands = [];
    const { data: brands } = await CustomerService.GetAllBrandsForCustomer();
    for (var item of brands) {
      var brand = new Brand(item);
      this.brands.push(brand);
    }
    var addNewBrand = new Brand();
    addNewBrand.Name = 'Add new Brand?';
    addNewBrand.Id = -1;

    this.brands.push(addNewBrand);
    this.selectedTab = this.tabs[0];
    this.active = this.selectedTab.name;
    if (!!this.existingItem && !!this.existingItem.BrandId && !this.brandSelect) {
      this.brandSelect = this.brands.find(b => b.Id == this.existingItem.BrandId)!;
    }
    if (defaultBrand) {
      this.brandSelect = this.brands[this.brands.length - 2];
    }
  }
  async GetStates() {
    const response = await AddressService.GetStates();
    this.states = Object.keys(response.data).map(key => ({
      id: key,
      name: response.data[key]
    }));
  }
  async getCountries() {
    const { data: countries } = await AddressService.GetCountries();
    this.countries = countries;
  }
  async autoGenPart() {
    this.isGeneratingPart = true;
    const { data } = await ItemService.AutoGenStaticPart();
    this.Part = data;
    this.isGeneratingPart = false;
  }
  isActive(name: string) {
    return this.active === name;
  }
  goToManageItems() {
    this.$router.push({ name: 'ManageItems', query: { refresh: 'true' } });
  }

  shouldShowFnskuPreviewBtn() {
    let selectedBrand = this.brands?.find(b => b.Id === this.brandSelect?.Id);
    return (
      this.selectedTab.IsType4 &&
      selectedBrand &&
      selectedBrand.HasBrandLogo &&
      selectedBrand.ShouldGenerate1Up
    );
  }

  isWin1252(str) {
    // Windows-1252 valid range: 0x20-0x7E, 0xA0-0xFF, excluding control characters
    if (!str) return true;
    for (let i = 0; i < str.length; i++) {
      const charCode = str.charCodeAt(i);
      if (
        !(charCode >= 0x20 && charCode <= 0x7e) &&
        !(charCode >= 0xa0 && charCode <= 0xff)
      ) {
        return false;
      }
    }
    return true;
  }
  /* Loaders */
  SetLoadedData(data: any) {
    this.gtinType = this.gtinTypes.find(g => g.id == data.GtinToUse);
    this.GTIN = data.Gtin;
  }
  async GetItemDetails(itemId: string) {
    const waitForSelectedTab = (staticItemId: string) => {
      setTimeout(() => {
        const selectedTab = this.tabs.find(
          (t: { publicId: string }) => t.publicId == staticItemId
        );
        if (selectedTab) {
          this.onGtinTypeChange(this.gtinType);
          this.selectedTab = selectedTab;
          this.active = this.selectedTab.name;
          this.updateHumanReadableFromSelectedLabelType();
        } else {
          waitForSelectedTab(staticItemId);
        }
      }, 500);
    };
    this.isLoadingStaticItems = true;
    const { data } = await ItemService.GetItemDetails(itemId);
    this.existingItem = data;
    this.gtinType = this.gtinTypes.find(g => g.id == data.GtinToUse).id;
    this.GTIN = data.Gtin;
    this.Description = data.Description;
    this.SKU = data.Sku;
    this.OldSku = data.Sku;
    this.ASIN = data.Asin;
    this.FNSKU = data.Fnsku;
    this.ManufacturingName = data.ManufacturingName;
    this.province = data.ManufacturingProvince;
    this.state = data.ManufacturingState;
    this.city = data.ManufacturerCity;
    this.country = this.countries.find(c => c.CountryValue == data.ManufacturingCountry);
    this.manufacturingCountryText = this.manufacturingCountryTextTypes.find(
      m => m.id == data.ManufacturedCountryTextType
    );
    this.NewUsed = data.IsNew;
    this.brandSelect = this.brands.find(b => b.Id == data.Brand.Id) as Brand;
    this.isLoadingStaticItems = false;
    this.varDataSelect = data.HumanReadable;
    waitForSelectedTab(data.StaticItemAssociation.PublicId);
  }
  async checkForDuplicates() {
    if (!this.SKU) return false;
    this.isCheckingForDuplicates = true;

    this.debouceAction = DateHelper.Debounce(async () => {
      let oldSku = this.SKU;
      const response = await ItemService.IsDuplicatePartNumber(this.SKU);
      let errorMsg = 'An item already has this sku';
      if (response.data && response.data.DupsFound && this.SKU !== this.OldSku) {
        this.skuErrorMessages.push(errorMsg);
      } else {
        this.filterSkuErrorMessages(errorMsg);
      }
      this.isCheckingForDuplicates = false;
    }, this.debouceAction);
  }
  filterSkuErrorMessages(msgToRemove: string) {
    this.skuErrorMessages = this.skuErrorMessages.filter(s => s !== msgToRemove);
  }
  /* Mounted */
  mounted() {}
  /* Created */
  async created() {
    this.isLoadingStaticItems = true;
    const promises = [
      this.getBrandsForCustomer(),
      this.getCountries(),
      this.GetStates(),
      this.getStaticItems()
    ];
    await Promise.all(promises);
    this.isLoadingStaticItems = false;
    if (this.isEditing) {
      this.GetItemDetails(this.$route.params.id);
    }
  }
  /* Computed */
  get availableVarDataTypes() {
    return this.varDataTypes.filter(
      (v: { gtin: number }) => v.gtin == this.gtinType || v.gtin == 5
    );
  }
  get gtinRules() {
    return [
      (v: any) => !!v || `${this.gtinTypeFullObject.Name} is required`,
      (v: any) =>
        v.length === this.gtinTypeFullObject.charLength ||
        `${this.gtinTypeFullObject.Name} must be ${this.gtinTypeFullObject.charLength} characters`,
      (v: any) => v !== '123456789014' || 'A valid GTIN is required.',
      (v: any) =>
        (v.length === this.gtinTypeFullObject.charLength && this.eanCheckDigit(v)) ||
        `GTIN is not valid expected check digit: ${this.expectedCheckDigit}`
    ];
  }
  get isEditing() {
    return this.$route.params.id;
  }
  /* Emmited Functions */
}
